import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import HelpIconComponent from 'Component/HelpIcon';

import { TOOLTIP_MSG } from './Tooltip.config';

import './Tooltip.style';

/** @namespace Theme/Component/Tooltip/Component */
export class TooltipComponent extends PureComponent {
    static propTypes = {
        tooltipMsg: PropTypes.oneOf(Object.values(TOOLTIP_MSG)).isRequired,
    };

    render() {
        const { tooltipMsg } = this.props;

        return (
            <div block="Tooltip" elem="Trigger">
                <div block="Tooltip" elem="IconWrap">
                    <HelpIconComponent />
                </div>
                <div block="Tooltip" elem="Popup">
                    <span block="Tooltip" elem="PopupArrow" />
                    <p block="Tooltip" elem="Content">
                        { tooltipMsg }
                    </p>
                </div>
            </div>
        );
    }
}

export default TooltipComponent;
