import { PureComponent } from 'react';

import './HelpIcon.style';

/** @namespace Theme/Component/HelpIcon/Component */
export class HelpIconComponent extends PureComponent {
    render() {
        return (
            <svg
              block="HelpIcon"
              width="15.5"
              height="15.5"
              viewBox="0 0 15.5 15.5"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path
                  id="icon-question-mark"
                  // eslint-disable-next-line max-len
                  d="M15.734-5.141a7.751,7.751,0,0,0-7.75-7.75,7.751,7.751,0,0,0-7.75,7.75,7.75,7.75,0,0,0,7.75,7.75A7.75,7.75,0,0,0,15.734-5.141ZM8.192-10.328c1.761,0,3.642,1.375,3.642,3.188,0,2.407-2.6,2.444-2.6,3.333v.042a.375.375,0,0,1-.375.375H7.109a.375.375,0,0,1-.375-.375v-.125c0-1.283.973-1.8,1.708-2.208.63-.353,1.017-.594,1.017-1.062,0-.619-.79-1.03-1.428-1.03-.85,0-1.232.411-1.791,1.119a.375.375,0,0,1-.521.066L4.635-7.828a.375.375,0,0,1-.085-.508A4.048,4.048,0,0,1,8.192-10.328Zm-.208,7.75A1.439,1.439,0,0,1,9.422-1.141,1.439,1.439,0,0,1,7.984.3,1.439,1.439,0,0,1,6.547-1.141,1.439,1.439,0,0,1,7.984-2.578Z"
                  transform="translate(-0.234 12.891)"
                />
            </svg>
        );
    }
}

export default HelpIconComponent;
