/* eslint-disable max-len */

export const TOOLTIP_MSG = {
    englishOnly: 'We require all entries in US English. You will be contacted if any non-US English characters are used for your order.',
    wwid: 'Please enter the WWID for the person that should pay for this order.',
    companyCode: 'Please enter your three (3) digits company code. To find your company code ask your groups finance representative, go to Circuit or look up your name at people.intel.com.',
    deptCostCode: 'Please enter your five (5) digits department cost code. To find your department cost code ask your group\'s finance representative, go to Circuit or look up your name at people.intel.com.',
    cvv: 'The three or four digit security code on the back of your card.',
    searchOrders: 'If searching by date, please enter in MM/DD/YYYY format.',
};
